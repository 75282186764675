.bold {
  font-weight: bold;
  /*color: black;*/
}

.mixedParagraph {
  flex-direction: row;
}

.textHeading {
  margin-top: 0;
  text-align: center;
}

.bulletedParagraph {
  display: flex;
  flex-direction: row;
}

.bulletColumn {
  margin-right: 1.5rem;
  min-width: 3ch;
}

.bulletDot {
  margin-top: -.6rem;
  font-size: 2rem;
}

.paragraphColumn {
}

.underline {
  text-decoration: underline;
  text-decoration-thickness: .1rem;
}

.address {
  display: flex;
  white-space: pre;
  flex-direction: row;
  justify-content: center;
}

/* Media query boilerplate from https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488 */

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  /* CSS */

}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

  /* CSS */

}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

  /* CSS */

}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */


}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {

  /* CSS */
  .wordmark {
    width: 90vw
  }

  .footer {
    font-size: .75rem
  }

  .comingSoon {
    font-size: 1.4rem;
  }

  .pane {
    max-width: 80vw;
    font-size: 1rem;
  }

  .header {
    height: 4rem;
  }

  .scrollToTop {
    margin-right: 2rem;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  /* CSS */
  .wordmark {
    width: 90vw
  }

  .footer {
    font-size: 1rem
  }

  .comingSoon {
    font-size: 1.4rem;
  }

  .pane {
    max-width: 80vw;
    font-size: 1rem;
  }

  .header {
    height: 4rem;
  }

  .scrollToTop {
    margin-right: 2rem;
  }
}
